import React, { useEffect, useState } from 'react';
// import SVGLeftArrow from '../materials/svg/SVGLeftArrow';
// import SVGRightArrow from '../materials/svg/SVGRightArrow';

const images = [
  'https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
  'https://www.pixelstalk.net/wp-content/uploads/2016/10/Building-Wallpapers-HD-Free-Download.jpg',
  'https://wallpaperaccess.com/full/3734607.jpg',
  // Add more image URLs here
];

const Carousel = ({ title, subtitle }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // const prevImage = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  // };

  // const nextImage = () => {
  //   setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  // };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [images]);

  return (<>
    {images.map((image, index) => (
      <div 
        key={index}
        className={`absolute top-0 w-full h-full bg-center bg-cover transition-opacity duration-1000 ${
        index === currentIndex ? 'opacity-100' : 'opacity-0'
        }`}
          style={{
              backgroundImage: `url(${image})`
          }}
      >
        <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
        {/* <button className="absolute top-1/2 left-4 transform -translate-y-1/2 p-2 bg-transparent text-white rounded" onClick={prevImage}>
          <SVGLeftArrow />
        </button>
        <button className="absolute top-1/2 right-4 transform -translate-y-1/2 p-2 bg-transparent text-white rounded" onClick={nextImage}>
          <SVGRightArrow />
        </button> */}
      </div>
    ))}
    <div className="container relative mx-auto">
      <div className="items-center flex flex-wrap">
        <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
          <div className="">
            <h1 className="text-white font-semibold text-5xl uppercase">
              {title}
            </h1>
            <p className={`${subtitle ? 'mt-4' : ''} text-lg text-gray-300`}>
              {subtitle}
            </p>
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default Carousel;