import React, { useEffect, useState } from 'react';


const FadeInOnScroll = ({ threshold, animationClass, children }) => {
    const [isVisible, setIsVisible] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        const contentElement = document.querySelector(`.${animationClass}`);
        if (contentElement) {
          const rect = contentElement.getBoundingClientRect();
          setIsVisible(rect.top <= window.innerHeight * threshold);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      handleScroll(); // Initial check
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [animationClass, threshold]);
  
    return (
      <div
        className={`transform transition-opacity duration-500 ${
          isVisible ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-5'
        } ${animationClass}`}
      >
        {children}
      </div>
    );
  };

export default FadeInOnScroll;
