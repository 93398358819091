import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import Carousel from "../../components/Carousel";
import FadeInOnScroll from "../../components/FadeInOnScroll";

const AboutUs = () => {
  const [width, setWidth] = useState(+(window.innerWidth));

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);
  
  const isMobile = width <= 768;
  const bgDesktop = isMobile ? '' : 'https://trumpwallpapers.com/wp-content/uploads/Building-Wallpaper-08-1920x1080-1.jpg';

    return (<div 
        className={`w-full h-full ${isMobile ? 'bg-gradient-to-r from-slate-300 to-gray-300' : 'bg-fixed'}`}
        style={isMobile ? {} : {
          backgroundImage: `url(${bgDesktop})`
        }}>
        <Navbar transparent />
        <main>
          <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
              style={{
                minHeight: "75vh"
              }}
            >
            <Carousel 
              title={'About us'}
            //   subtitle={'This is a simple example of a Landing Page you can build using Tailwind Starter Kit. It features multiple CSS components based on the Tailwindcss design system.'}
            />
          </div>
          <section className="pb-20 -mt-24">
            {/* <div className="container mx-auto px-4 items-center">
                <div className="flex flex-wrap items-center justify-center mt-32">
                    <h1 className="text-5xl mb-2 font-bold leading-normal uppercase text-white">
                      Investment
                    </h1>
                </div>
            </div> */}
            <div className="container mx-auto px-4 bg-transparent">
              <div className="flex flex-wrap items-center backdrop-blur p-10 mt-32">
                <div className="w-full px-4 mr-auto ml-auto">
                  <FadeInOnScroll threshold={0.6} animationClass="animation-1">
                    <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-transparent">
                      <i className="fas fa-user-friends text-xl"></i>
                    </div>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-1">
                    <h3 className="text-3xl mb-2 font-semibold leading-normal">
                      Working with us is a pleasure
                    </h3>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-1">
                    <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                      Don't let your uses guess by attaching tooltips and popoves
                      to any element. Just make sure you enable them first via
                      JavaScript.
                    </p>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-1">
                    <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go. Just make sure you enable them first via
                      JavaScript.
                    </p>
                  </FadeInOnScroll>
                  
                  <FadeInOnScroll threshold={0.6} animationClass="animation-1">
                    <a
                      href="http://localhost:3000/"
                      className="font-bold text-gray-800 mt-8"
                    >
                      Contact Us
                    </a>
                  </FadeInOnScroll>
                </div>
              </div>
            </div>
          </section>
          {/* <section className="pb-20 -mt-24">
            <div className="container mx-auto px-4 items-center">
                <div className="flex flex-wrap items-center justify-center mt-32">
                    <h1 className="text-5xl mb-2 font-bold leading-normal uppercase text-white">
                      Technology
                    </h1>
                </div>
            </div>
            <div className="container mx-auto px-4 bg-transparent">
              <div className="flex flex-wrap items-center backdrop-blur p-10">
                <div className="w-full px-4 mr-auto ml-auto">
                  <FadeInOnScroll threshold={0.6} animationClass="animation-2">
                    <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-transparent">
                      <i className="fas fa-user-friends text-xl"></i>
                    </div>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-2">
                    <h3 className="text-3xl mb-2 font-semibold leading-normal">
                      Working with us is a pleasure
                    </h3>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-2">
                    <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                      Don't let your uses guess by attaching tooltips and popoves
                      to any element. Just make sure you enable them first via
                      JavaScript.
                    </p>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-2">
                    <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go. Just make sure you enable them first via
                      JavaScript.
                    </p>
                  </FadeInOnScroll>
                  
                  <FadeInOnScroll threshold={0.6} animationClass="animation-2">
                    <a
                      href="http://localhost:3000/"
                      className="font-bold text-gray-800 mt-8"
                    >
                      Contact Us
                    </a>
                  </FadeInOnScroll>
                </div>
              </div>
            </div>
          </section>
          <section className="pb-20 -mt-24">
            <div className="container mx-auto px-4 items-center">
                <div className="flex flex-wrap items-center justify-center mt-32">
                    <h1 className="text-5xl mb-2 font-bold leading-normal uppercase text-white">
                      Others
                    </h1>
                </div>
            </div>
            <div className="container mx-auto px-4 bg-transparent">
              <div className="flex flex-wrap items-center backdrop-blur p-10">
                <div className="w-full px-4 mr-auto ml-auto">
                  <FadeInOnScroll threshold={0.6} animationClass="animation-3">
                    <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-transparent">
                      <i className="fas fa-user-friends text-xl"></i>
                    </div>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-3">
                    <h3 className="text-3xl mb-2 font-semibold leading-normal">
                      Working with us is a pleasure
                    </h3>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-3">
                    <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                      Don't let your uses guess by attaching tooltips and popoves
                      to any element. Just make sure you enable them first via
                      JavaScript.
                    </p>
                  </FadeInOnScroll>
                  <FadeInOnScroll threshold={0.6} animationClass="animation-3">
                    <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                      The kit comes with three pre-built pages to help you get
                      started faster. You can change the text and images and
                      you're good to go. Just make sure you enable them first via
                      JavaScript.
                    </p>
                  </FadeInOnScroll>
                  
                  <FadeInOnScroll threshold={0.6} animationClass="animation-3">
                    <a
                      href="http://localhost:3000/"
                      className="font-bold text-gray-800 mt-8"
                    >
                      Contact Us
                    </a>
                  </FadeInOnScroll>
                </div>
              </div>
            </div>
          </section> */}
        </main>
        <Footer />
    </div>)
}

export default AboutUs;