import {
  Route,
  Routes,
  HashRouter
} from "react-router-dom";
import Home from "./screens/home/Home";
import Products from "./screens/products/Products";
import AboutUs from "./screens/about-us/AboutUs";
import News from "./screens/news/News";

const App = () => {
  return (
    <>
      <HashRouter>
        <Routes>

          <Route path='' element={<Home />} />
          <Route path='/our-products' element={<Products />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/news' element={<News />} />

        </Routes>
      </HashRouter>
    </>
  );
}

export default App;